import React from 'react'

export function SuccessIcon() {
    return (
        <div className = "success-icon">
            <svg  width="70" height="70" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M55.9746 28.2695H52.2189C51.4021 28.2695 50.6254 28.6619 50.1449 29.3346L37.5566 46.7916L31.8551 38.8799C31.3746 38.2152 30.6058 37.8148 29.781 37.8148H26.0254C25.5049 37.8148 25.2006 38.4074 25.5049 38.8318L35.4826 52.6693C35.7183 52.9983 36.029 53.2664 36.389 53.4513C36.749 53.6362 37.1479 53.7327 37.5526 53.7327C37.9573 53.7327 38.3562 53.6362 38.7162 53.4513C39.0762 53.2664 39.3869 52.9983 39.6226 52.6693L56.4871 29.2865C56.7994 28.8621 56.4951 28.2695 55.9746 28.2695Z" fill="#09B47C"/>
                <path d="M41 5.125C21.1887 5.125 5.125 21.1887 5.125 41C5.125 60.8113 21.1887 76.875 41 76.875C60.8113 76.875 76.875 60.8113 76.875 41C76.875 21.1887 60.8113 5.125 41 5.125ZM41 70.7891C24.552 70.7891 11.2109 57.448 11.2109 41C11.2109 24.552 24.552 11.2109 41 11.2109C57.448 11.2109 70.7891 24.552 70.7891 41C70.7891 57.448 57.448 70.7891 41 70.7891Z" fill="#09B47C"/>
            </svg>
        </div>
    )
}


