import React, {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import FailurePage from "./components/failure";
import SuccessPage from './components/success';

function App() {


  const search = useLocation().search;
  const result = (new URLSearchParams(search).get("result"));

/*     
  useEffect(() => {
    // setParameter({currentPage: "success"})
     (result === "SUCCESS") ?  <SuccessPage /> :  <FailurePage />
   }, [])  */

  return (
    <div className="App">
      <div className="App">
     { (result && result === "SUCCESS") ?  <SuccessPage /> :  <FailurePage />}
    </div>
    </div>
  );
}

export default App;
