import React from 'react'
import {FailureIcon} from './FailureIcon';
import '../styles/style.css';


const FailurePage = () => {


    return (
        <div className="completion-section">
            <div className="details">
                <FailureIcon />
                <p className="details-title">Payment Declined</p>
       
            </div>
        </div>
    )
}

export default FailurePage;
