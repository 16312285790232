import React from 'react'
import { SuccessIcon } from './SuccessIcon';
import '../styles/style.css'






const SuccessPage = () => {


    return (
        <div className="completion-section">
            <div className="details">
                <SuccessIcon />
                <p className="details-title">Payment Successful</p>
       
            </div>
        </div>
    )
}

export default SuccessPage;
