import React from 'react'

export function FailureIcon() {
    return (
        <div className = "success-icon">
            <svg  width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41 5.125C21.1887 5.125 5.125 21.1887 5.125 41C5.125 60.8113 21.1887 76.875 41 76.875C60.8113 76.875 76.875 60.8113 76.875 41C76.875 21.1887 60.8113 5.125 41 5.125ZM41 70.7891C24.552 70.7891 11.2109 57.448 11.2109 41C11.2109 24.552 24.552 11.2109 41 11.2109C57.448 11.2109 70.7891 24.552 70.7891 41C70.7891 57.448 57.448 70.7891 41 70.7891Z" fill="#7F1D1D"/>
<path d="M37.1562 55.0938C37.1563 56.1132 37.5612 57.0908 38.2821 57.8117C39.0029 58.5325 39.9806 58.9375 41 58.9375C42.0194 58.9375 42.9971 58.5325 43.7179 57.8117C44.4388 57.0908 44.8438 56.1132 44.8438 55.0938C44.8438 54.0743 44.4388 53.0967 43.7179 52.3758C42.9971 51.655 42.0194 51.25 41 51.25C39.9806 51.25 39.0029 51.655 38.2821 52.3758C37.5612 53.0967 37.1563 54.0743 37.1562 55.0938ZM39.0781 46.125H42.9219C43.2742 46.125 43.5625 45.8367 43.5625 45.4844V23.7031C43.5625 23.3508 43.2742 23.0625 42.9219 23.0625H39.0781C38.7258 23.0625 38.4375 23.3508 38.4375 23.7031V45.4844C38.4375 45.8367 38.7258 46.125 39.0781 46.125Z" fill="#7F1D1D"/>
</svg>
   </div>
      
    )
}
